let old_je = 'jahrliche_erzeugung';
let old_mo = 'anzahl_module';
let old_kwp = 'anzahl_kwp';
let old_mev = 'monatliche_ersparnisVon';
let old_meb = 'monatliche_ersparnisBis';
let old_ze = '20_jahre';
let old_co = 'co2_jahr';
let old_bj = 'baum_jahr';
let old_km = 'km_jahr';
let old_rent = 'miet_rate';
let old_direct = 'sofort_kauf';
let old_cs = 'contact_savings';
let old_kwh = 'storage_kwh';
let bat = 0;

function getGoogleClickIDFromCookie() {
    if (typeof document === 'undefined') {
        return undefined;
    }

    const cookieName = 'gid';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


function getRuidTokenFromCookie() {
    if (typeof document === 'undefined') {
        return undefined;
    }

    const cookieName = 'ruid=';
    const ca = document.cookie.split(/;\s*/);

    for (let i = ca.length - 1; i >= 0; i--) {
        if (!ca[i].indexOf(cookieName)) {
            return ca[i].replace(cookieName, '');
        }
    }

    return undefined;
}

function getInputByName(name) {
    const allVariableElements = document.querySelectorAll("[data-variable]");
    return Array.from(allVariableElements).find(
        (el) => el.dataset.variable === name
    );
}

window.addEventListener('heyflow-screen-view', (event) => {
    console.log('heyflow screen view:', event.detail);
    if (event.detail.screenName == 'consumption' && old_mo == 'anzahl_module') {
        calculate(120,0);
    } else if (event.detail.screenName == 'contact') {
        const ruid = getRuidTokenFromCookie();
        console.log('RUID', ruid);
        if (ruid) {
            const ruidInput = getInputByName('ruid');
            ruidInput.value = ruid;
            ruidInput.dispatchEvent(new Event('input'));
        }
        
        const gclickid = getGoogleClickIDFromCookie();
if (gclickid) {
   const gclickidInput = getInputByName('gclickid');
   gclickidInput.value = gclickid;
   gclickidInput.dispatchEvent(new Event('input'));
}
    }
});

window.addEventListener('heyflow-change', (event) => {
    console.log('consumption: ' + event.detail.fieldsSimple['consumption [numeric]']);
    bat = event.detail.fieldsSimple['storage']
    if (event.detail.fieldsSimple['consumption [numeric]'] != null) {
        calculate(event.detail.fieldsSimple['consumption [numeric]'],bat);
        
        const consumption2Input = getInputByName('consumption2');
        consumption2Input.value = Number(event.detail.fieldsSimple['consumption [numeric]']);
        consumption2Input.dispatchEvent(new Event('input'));
    }
});

function getElementByXpath(path) {
  return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
}

function calculate(yec) {
    let ekc = 0.35;
    let ekf = 0.12;
    let psf = 1.25;
    let kpk = 1075;
    let kpp = 0.4;
    let sc = 0.45;
    let zja = 0.83;
    let ckk = 330;
    let tk = 11.31;
    let km = 6667;
    let kwh = 0;
    let kwp1 = 0;
    
    if (bat == 'storage') {
        sc = 0.8;
    }
        
    let kcy = yec;
    let pp = kcy * psf;
    let kwp = pp / kpk;
    let pan = Math.ceil(kwp / kpp);
    if (pan > 40) {
        pan = 40;
    }
    if (pan < 6) {
        pan = 6;
    }
    let ksc = ((pan * kpp).toFixed(1)*kpk)*sc;
    let kfi = ((pan * kpp).toFixed(1)*kpk)*(1-sc);
    let sm = Math.ceil(((ksc*ekc)+(kfi*ekf))/12);
    
    // Solar Module
    headings = document.evaluate("//span[contains(text(),'" + old_mo + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_mo = pan + ' Paneele';
    thisHeading.innerHTML = old_mo;
    
    // kWp
    headings = document.evaluate("//span[contains(text(),'" + old_kwp + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_kwp = (pan * kpp).toFixed(1) + ' kWp ';
    kwp1 = (pan * kpp);
    thisHeading.innerHTML = old_kwp;
    
    // kWh
    headings = document.evaluate("//strong[contains(text(),'" + old_kwh + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    if (bat == 'storage') {
        kwh = kwp1 * 1.2;
        if (kwh <= 3.5) {
            kwh = 3.5;
        } else if (kwh <= 7) {
            kwh = 7;
        } else {
            kwh = 10.5;
        }
    }
    old_kwh = 'Stromspeicher: ' + kwh + ' kWh';
    thisHeading.innerHTML = old_kwh;
    
    // Jährliche Erzeugung
    headings = document.evaluate("//span[contains(text(),'" + old_je + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_je = ((pan * kpp).toFixed(1)*kpk).toLocaleString('de') + ' kWh';
    thisHeading.innerHTML = old_je;
    
    // Monatliche Ersparnis
    headings = document.evaluate("//strong[contains(text(),'" + old_mev + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_mev = Math.ceil(sm * 0.9) + ' € - ' + Math.ceil(sm * 1.1) + ' € monatlich';
    thisHeading.innerHTML = old_mev;
    
    // Monatliche Ersparnis Contact
    headings = document.evaluate("//strong[contains(text(),'" + old_cs + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_cs = Math.ceil(sm * 0.9) + ' € - ' + Math.ceil(sm * 1.1) + ' € monatlich';
    thisHeading.innerHTML = old_cs;
    
    // Ersparnis 20 Jahre
    //headings = document.evaluate("//div[contains(text(),'" + old_ze + "')]", document, null, XPathResult.ANY_TYPE, null );
    //thisHeading = headings.iterateNext();
    //old_ze = 'ca. '+ (Math.ceil((((sm*12)*20)/zja)/100)*100).toLocaleString('de') + ' €';
    //thisHeading.innerHTML = old_ze;
    
    // Ersparnis CO2
    //headings = document.evaluate("//div[contains(text(),'" + old_co + "')]", document, null, XPathResult.ANY_TYPE, null );
    //thisHeading = headings.iterateNext();
    //old_co = ((pan * kpp).toFixed(1) * ckk).toLocaleString('de') + ' kg';
    //thisHeading.innerHTML = old_co;
    
    // Bäume pro Jahr
    //headings = document.evaluate("//div[contains(text(),'" + old_bj + "')]", document, null, XPathResult.ANY_TYPE, null );
    //thisHeading = headings.iterateNext();
    //old_bj = Math.ceil((pan * kpp).toFixed(1) * tk);
    //thisHeading.innerHTML = old_bj;
    
    // KM Auto
    //headings = document.evaluate("//div[contains(text(),'" + old_km + "')]", document, null, XPathResult.ANY_TYPE, null );
    //thisHeading = headings.iterateNext();
    //old_km = Math.ceil((pan * kpp).toFixed(1) * km).toLocaleString('de') + ' km';
    //thisHeading.innerHTML = old_km;
    
let rent = 0;
let direct = 0;

switch (pan) {
  case 6:
    rent = 45.11;
    direct = 6890;
    break;
  case 7:
    rent = 49.03;
    direct = 7490;
    break;
  case 8:
    rent = 52.31;
    direct = 7990;
    break;
  case 9:
    rent = 57.54;
    direct = 8790;
    break;
  case 10:
    rent = 62.23;
    direct = 9590;
    break;
  case 11:
    rent = 68.67;
    direct = 10490;
    break;
  case 12:
    rent = 73.91;
    direct = 11290;
    break;
  case 13:
    rent = 79.15;
    direct = 12090;
    break;
  case 14:
    rent = 84.93;
    direct = 12890;
    break;
  case 15:
    rent = 88.97;
    direct = 13590;
    break;
  case 16:
    rent = 94.86;
    direct = 14490;
    break;
  case 17:
    rent = 99.86;
    direct = 15390;
    break;
  case 18:
    rent = 104.41;
    direct = 16090;
    break;
  case 19:
    rent = 109.92;
    direct = 16790;
    break;
  case 20:
    rent = 115.16;
    direct = 17590;
    break;
  case 21:
    rent = 119.74;
    direct = 18290;
    break;
  case 22:
    rent = 125.63;
    direct = 19190;
    break;
  case 23:
    rent = 130.21;
    direct = 19890;
    break;
  case 24:
    rent = 134.14;
    direct = 20490;
    break;
  case 25:
    rent = 140.03;
    direct = 21390;
    break;
  case 26:
    rent = 143.99;
    direct = 22190;
    break;
  case 27:
    rent = 148.99;
    direct = 22690;
    break;
  case 28:
    rent = 153.78;
    direct = 23490;
    break;
  case 29:
    rent = 159.02;
    direct = 24290;
    break;
  case 30:
    rent = 162.95;
    direct = 24890;
    break;
  case 31:
    rent = 168.18;
    direct = 25690;
    break;
  case 32:
    rent = 173.42;
    direct = 26490;
    break;
  case 33:
    rent = 176.04;
    direct = 26890;
    break;
  case 34:
    rent = 181.28;
    direct = 27690;
    break;
  case 35:
    rent = 186.51;
    direct = 28490;
    break;
  case 36:
    rent = 189.79;
    direct = 28990;
    break;
  case 37:
    rent = 194.37;
    direct = 29690;
    break;
  case 38:
    rent = 199.61;
    direct = 30490;
    break;
  case 39:
    rent = 204.84;
    direct = 31290;
    break;
  case 40:
    rent = 210.08;
    direct = 32090;
    break;
  default:
    break;
}

    if (kwh == 3.5) {
        direct = direct + 5890;
    } else if (kwh == 7) {
        direct = direct + 9790;
    } else if (kwh == 10.5) {
        direct = direct + 14390;
    }
    
    //calculate 0% VAT for dp 
    direct = Math.ceil(direct/1.2);
    
    //change from rent to financing = loan already with 0% VAT in list

    //Miete
    headings = document.evaluate("//div[contains(text(),'" + old_rent + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_rent = 'ab ' + Math.floor(rent) + ' €';
    thisHeading.innerHTML = old_rent;
    
    // Direkt
    headings = document.evaluate("//div[contains(text(),'" + old_direct + "')]", document, null, XPathResult.ANY_TYPE, null );
    thisHeading = headings.iterateNext();
    old_direct = direct.toLocaleString('de') + ' €';
    thisHeading.innerHTML = old_direct;
}

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});
